import type { VebraPrice } from '@/types'

export function useTruncate() {
  const extractTextFromSanityBlocks = (blocks: any) => {
    const textArray: any[] = []
    function extractTextFromBlock(block: { _type: string, children: any[] }) {
      if (block._type === 'block' && block.children) {
        block.children.forEach((child: { _type: string, text: any }) => {
          if (child._type === 'span' && child.text) {
            textArray.push(child.text)
          }
        })
      }
      else if (block._type === 'image') {
        // Handle images if needed
      }
    }

    blocks.forEach((block: any) => {
      extractTextFromBlock(block)
    })

    return textArray.join(' ')
  }

  const firstSentence = (str: string) => {
    const sentences = str.split('.')
    return `${sentences[0]}.`
  }

  const firstSanitySentence = (blocks: any) => {
    const text = extractTextFromSanityBlocks(blocks)
    return firstSentence(text)
  }

  // truncate function to trim text to 200 characters at nearest space
  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, str.lastIndexOf(' ', n))}...` : str
  }

  const extractAndTruncate = (blocks: any, n: number) => {
    const text = extractTextFromSanityBlocks(blocks)
    return truncate(text, n)
  }

  // this function removes numbers along with any commas and spaces that follow the number
  const removeStreetNumbers = (text: string) => {
    // include numbers if the text includes one of the following words
    const exceptions = ['unit', '&']
    if (exceptions.some(exception => text.toLowerCase().includes(exception))) {
      return text
    }
    return text.replace(/\d+[\s,]*/g, '')
  }

  const postcodeMain = (postcode: string | undefined) => {
    if (!postcode)
      return ''
    return postcode.split(' ')[0]
  }

  const priceString = (price: VebraPrice, database: string) => {
    if (!price || !price.amount || !price.qualifier) {
      return ''
    }

    const { format } = usePrice()
    return `${database === '2' ? '' : `${price.qualifier} - `} ${format(price)}${database === '2' ? ' PCM' : ''}`
  }

  return {
    extractTextFromSanityBlocks,
    removeStreetNumbers,
    priceString,
    truncate,
    extractAndTruncate,
    postcodeMain,
    firstSentence,
    firstSanitySentence,
  }
}
